const handleModalOpenQuery = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if(params.has('show_account_panel')) {
    const button: HTMLElement = document.querySelector('.account-panel__button--toggle[data-a11y-dialog-show="account-panel-menu"]');
    if(button) {
      button.click();
    }
  }
};

const handleModalOpen = (e) => {
  let dialog;

  /*
    site registered user:
    ```
    {
      "omeda_id": "1131574791",
      "user_purchased_subscription": false,
      "user_has_expired_subscription": false,
      "member_type": "free"
    }
    ```

    unlimited user:
    ```
    {
      "omeda_id": "1132949813",
      "user_purchased_subscription": true,
      "user_has_expired_subscription": false,
      "member_type": "member",
      "subscription": "unlimited",
      "start_date": "Feb 21, 2023",
      "end_date": "Apr 21, 2023",
      "auto_renewal": false,
      "free_trial": false,
      "comp": false,
      "alert": true,
      "expire_days": 58
    }
    ```

    digital only:
    ```
    {
      "omeda_id": "1130984636",
      "user_purchased_subscription": true,
      "user_has_expired_subscription": false,
      "member_type": "member",
      "subscription": "digital",
      "start_date": "Jan 24, 2023",
      "end_date": "Apr 24, 2023",
      "auto_renewal": false,
      "free_trial": false,
      "comp": false,
      "alert": false,
      "expire_days": 61
    }
    ```

    user-type mismatch:
    ```
    {
      "session_redirect": true,
      "redirect_url": "https:\/\/dev.finewoodworking.com\/user\/session?ap_validate_session=1&return_url=https%3A%2F%2Fdev.finewoodworking.com%23show_account_panel"
    }
    ```

    expiring member with no auto-renew:
    ```
    {
      "omeda_id": "1132949813",
      "user_purchased_subscription": true,
      "user_has_expired_subscription": false,
      "member_type": "member",
      "subscription": "unlimited",
      "start_date": "Feb 21, 2023",
      "end_date": "Apr 21, 2023",
      "auto_renewal": false,
      "free_trial": false,
      "comp": false,
      "alert": true,
      "expire_days": 58
    }
    ```

    print-only:
    ```
    {
      "omeda_id": "1130984940",
      "user_purchased_subscription": true,
      "user_has_expired_subscription": false,
      "member_type": "free",
      "subscription": "print_only",
      "start_date": "Jan 24, 2023",
      "end_date": "Jun 01, 2023",
      "auto_renewal": false,
      "free_trial": false,
      "comp": false,
      "alert": false,
      "expire_days": 99
    }
    ```

    Error fetching data
    ```
    {
      error: "SUBSCRIPTION_API_ERROR"
    }
    ```
   */

  if (e.target.closest('[data-a11y-dialog-show="account-panel-menu"]')) {
    import('./init').then(module => {
      dialog = module.default();
      if (dialog) {
        dialog.show();
        window.removeEventListener('click', handleModalOpen);
      }

      return fetch('/wp/wp-admin/admin-ajax.php?action=get_account_panel_data');
    }).then(res => {
      return res.json();
    }).then(res => {
        if(res.session_redirect && res.redirect_url) {
          window.location.href = res.redirect_url;
        } else {
          const target: HTMLElement = document.querySelector('#account-panel-template-target');
          const template: HTMLElement = document.querySelector('#account-panel-template');
          const data = {
            data: res
          };
          if(target && template) {
            const compiled = _.template(template.innerHTML);
            target.innerHTML = compiled(data);
          } else {
            console.error('The template or target container is missing from the DOM.');
          }
          if(res.user_purchased_subscription) {
            const item: HTMLElement = document.querySelector('.account-panel__list-item--manage.is-hidden');
            if(item) {
              item.classList.remove('is-hidden');
            }
            if(res.subscription !== 'digital' && res.subscription !== 'unlimited') {
              item.querySelector('a').innerHTML = 'Manage your subscription &raquo;';
            }
          }
        }
    }).catch(err => {
      console.log('error',err);
    });
  }
};

export default (): void => {
  window.addEventListener('click', handleModalOpen);

  handleModalOpenQuery();
}
