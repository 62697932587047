export default ($action: string) => {

  return new Promise((resolve, reject) => {
    if (FWW.util.reCaptchaKey !== "") {
      grecaptcha.ready(function() {
        grecaptcha.execute(FWW.util.reCaptchaKey, {action: $action}).then(function(token: any) {
          //submit to server to validate token
          var formData = {
            action: 'fww_validate_reCaptcha',
            token: token,
            captcha_action: $action
          };

          $.ajax({
            type: 'POST',
            url: '/wp/wp-admin/admin-ajax.php',
            dataType: 'json',
            data: formData,
            error: function (result: any) {
              reject(result);
            },
            success: function (result: { passedReCaptcha: boolean; error: string; response: any }) {
              if (result.passedReCaptcha === true) {
                resolve(result);
              } else {
                reject(result);
              }
            }
          });
        });
      });
    } else {
      // If Recaptcha is turned off, just submit the sucess function.
      resolve(null);
    }

  });
}
