import menuRollup from './menu/menu-rollup';
import recentQuestions from './recent-questions/recent-questions';
import contentBrowser from './content-browser/content-browser';
import setLinkTarget from './behavior/set-link-target';
import userTimings from '../user-timings';
import prebid from './analytics/prebid';
import setHeaderWaypoint from './set-header-waypoint/set-header-waypoint';
import expandableMenu from './expandable-menu/expandable-menu';
import initMembershipsFeatures from './memberships/init-memberships-features';
import emailOnlyForms from './form-submit/email-only-forms';
import newsletterSplash from './form-submit/newsletter-splash';
import partnerOptin from './form-submit/partner-optin';
import eventRegistration from './form-submit/event-registration';
import toggleComments from './comments/toggle-comments';
import responsiveEmbeds from './responsive/responsive-embeds';
import amazonPixel from './analytics/amazon-pixel';
import recordSelectorUsage from './analytics/record-selector-usage';
import emblaCarousel from './carousel/embla-carousel';
import accountPanel from './account-panel/account-panel';
import imageZoom from "./article-gallery/image-zoom";
import imageCarousel from "./article-gallery/image-carousel";
import renderABTemplate from "./a-b-test/render-a-b-template";

menuRollup();
recentQuestions();
contentBrowser();
setLinkTarget();
userTimings();
expandableMenu()
emailOnlyForms();
newsletterSplash();
partnerOptin();
eventRegistration();
initMembershipsFeatures();
toggleComments();
responsiveEmbeds();
emblaCarousel();
amazonPixel();
recordSelectorUsage();
accountPanel();
imageZoom('.js-image-zoom-wrapper');
imageZoom('.article__wrap.question');
imageZoom('.article--guide');
imageCarousel();
renderABTemplate();

document.addEventListener('DOMContentLoaded', () => {
  prebid();
  setHeaderWaypoint();
});
