export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    TauntonUserConsent.user.consent.analytics.then(function() {
      //CFW-599: Replace all pixels with new one
      let _pix = document.getElementById('_pix_id_b30435cd-0412-569d-5127-34abd0208b30');
      if (!_pix) {
        const protocol = '//';
        const a = Math.random() * 1000000000000000000;
        _pix = document.createElement('iframe');
        _pix.style.display = 'none';
        _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Db30435cd-0412-569d-5127-34abd0208b30%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://subscribe.finewoodworking.com/&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DB30435CD-0412-569D-5127-34ABD0208B30' + '&cb=' + a);
        _pix.setAttribute('id', '_pix_id_b30435cd-0412-569d-5127-34abd0208b30');
        document.body.appendChild(_pix);
      }
      //Inserting "noscript" with javascript is just silly - in template layout.php...
      //$('body').append("<noscript><img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Db30435cd-0412-569d-5127-34abd0208b30%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://subscribe.finewoodworking.com/&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DB30435CD-0412-569D-5127-34ABD0208B30' /></noscript>");

      /*var router = new Navigo(location.protocol + '//' + location.hostname, false);

      router.on({
        '/': function () {
          $('body').append('<!-- Amazon DSP Pixel -->');
          var _pix = document.getElementById('_pix_id_4d0ad7d2-33a6-39b1-1051-805709836f26');
          if (!_pix) {
            var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D4d0ad7d2-33a6-39b1-1051-805709836f26%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D4D0AD7D2-33A6-39B1-1051-805709836F26' + '&cb=' + a); _pix.setAttribute('id','_pix_id_4d0ad7d2-33a6-39b1-1051-805709836f26'); document.body.appendChild(_pix);
          }
          $('body').append("<noscript><img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D4d0ad7d2-33a6-39b1-1051-805709836f26%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D4D0AD7D2-33A6-39B1-1051-805709836F26' /></noscript>");
        },
        '/members': function () {
          $('body').append('<!-- Amazon DSP Pixel -->');
          var _pix = document.getElementById('_pix_id_3bb0abf1-36ef-5f52-895d-06aad2dbbe45');
          if (!_pix) {
            var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D3bb0abf1-36ef-5f52-895d-06aad2dbbe45%26type%3D44%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D3BB0ABF1-36EF-5F52-895D-06AAD2DBBE45' + '&cb=' + a); _pix.setAttribute('id','_pix_id_3bb0abf1-36ef-5f52-895d-06aad2dbbe45'); document.body.appendChild(_pix);
          }
          $('body').append("<noscript><img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D3bb0abf1-36ef-5f52-895d-06aad2dbbe45%26type%3D44%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D3BB0ABF1-36EF-5F52-895D-06AAD2DBBE45' /></noscript>");

        },
        '/magazine': function () {
          $('body').append('<!-- Amazon DSP Pixel -->');
          var _pix = document.getElementById('_pix_id_9310fd6c-b8e7-f81d-ad47-7cce04475e84');
          if (!_pix) {
            var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D9310fd6c-b8e7-f81d-ad47-7cce04475e84%26type%3D7%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D9310FD6C-B8E7-F81D-AD47-7CCE04475E84' + '&cb=' + a); _pix.setAttribute('id','_pix_id_9310fd6c-b8e7-f81d-ad47-7cce04475e84'); document.body.appendChild(_pix);
          }
          $('body').append("<noscript> <img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D9310fd6c-b8e7-f81d-ad47-7cce04475e84%26type%3D7%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D9310FD6C-B8E7-F81D-AD47-7CCE04475E84' /></noscript>");
        },
        '/videos': function () {
          $('body').append('<!-- Amazon DSP Pixel -->');
          var _pix = document.getElementById('_pix_id_986bba32-a3bb-97e1-bb35-689090a015cd');
          if (!_pix) {
            var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D986bba32-a3bb-97e1-bb35-689090a015cd%26type%3D56%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D986BBA32-A3BB-97E1-BB35-689090A015CD' + '&cb=' + a); _pix.setAttribute('id','_pix_id_986bba32-a3bb-97e1-bb35-689090a015cd'); document.body.appendChild(_pix);
          }
          $('body').append("<noscript><img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D986bba32-a3bb-97e1-bb35-689090a015cd%26type%3D56%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3D986BBA32-A3BB-97E1-BB35-689090A015CD' /></noscript>");
        },
        '/tools-materials': function () {
          $('body').append('<!-- Amazon DSP Pixel -->');
          var _pix = document.getElementById('_pix_id_a2ceaafa-fb5c-c927-95ee-c7e4e1521c65');
          if (!_pix) {
            var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Da2ceaafa-fb5c-c927-95ee-c7e4e1521c65%26type%3D17%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DA2CEAAFA-FB5C-C927-95EE-C7E4E1521C65' + '&cb=' + a); _pix.setAttribute('id','_pix_id_a2ceaafa-fb5c-c927-95ee-c7e4e1521c65'); document.body.appendChild(_pix);
          }
          $('body').append("<noscript><img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Da2ceaafa-fb5c-c927-95ee-c7e4e1521c65%26type%3D17%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DA2CEAAFA-FB5C-C927-95EE-C7E4E1521C65' /></noscript>");
        },
        '/how-to': function () {
          $('body').append('<!-- Amazon DSP Pixel -->');
          var _pix = document.getElementById('_pix_id_dbade2f1-6b72-a35a-91db-3d402422252d');
          if (!_pix) {
            var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Ddbade2f1-6b72-a35a-91db-3d402422252d%26type%3D13%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DDBADE2F1-6B72-A35A-91DB-3D402422252D' + '&cb=' + a); _pix.setAttribute('id','_pix_id_dbade2f1-6b72-a35a-91db-3d402422252d'); document.body.appendChild(_pix);
          }
          $('body').append("<noscript><img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Ddbade2f1-6b72-a35a-91db-3d402422252d%26type%3D13%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DDBADE2F1-6B72-A35A-91DB-3D402422252D' /></noscript>");
        },
        '/projects-and-plans': function () {
          $('body').append('<!-- Amazon DSP Pixel -->');
          var _pix = document.getElementById('_pix_id_e988cd7d-5206-79b0-58c7-049656343642');
          if (!_pix) {
            var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3De988cd7d-5206-79b0-58c7-049656343642%26type%3D43%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DE988CD7D-5206-79B0-58C7-049656343642' + '&cb=' + a); _pix.setAttribute('id','_pix_id_e988cd7d-5206-79b0-58c7-049656343642'); document.body.appendChild(_pix);
          }
          $('body').append("<noscript><img height='1' width='1' border='0' alt='' src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3De988cd7d-5206-79b0-58c7-049656343642%26type%3D43%26m%3D1&ex-fch=416613&ex-src=https://www.finewoodworking.com&ex-hargs=v%3D1.0%3Bc%3D6552841750701%3Bp%3DE988CD7D-5206-79B0-58C7-049656343642' /></noscript>");
        }
      }).resolve();
      */

    });

  });
}
