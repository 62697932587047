import $ from "jquery";

const calcWidth = () => {
  // return if the browser doesn't support array reduce
  if(!Array.prototype.reduce) {
    return;
  }

  var navWidth = 0,
    $moreMenu = $('#main-roll-up-list .more'),
    spaceEaters = [
      $('.siteheader__nav').outerWidth(true),
      $('.siteheader__account-panel').outerWidth(true),
      $moreMenu.outerWidth(true),
      40 // give it a little bit more breathing room
    ],
    $menuLinks = $('#main-roll-up-list > li:not(.more)'),
    $moreMenuList = $('#main-roll-up-list .more > ul'),
    moreLinksCount = 0;

  $menuLinks.each(function() {
    navWidth += $(this).outerWidth(true);
  });

  if(navWidth === 0) {
    return;
  }

  var availableSpace = spaceEaters.reduce(function(item, currentVal) {
    return item - currentVal;
  });

  while (navWidth > availableSpace) {
    var lastItem = $('#main-roll-up-list > li:not(.more)').last(),
      lastItemWidth = lastItem.outerWidth(true);

    if(!lastItem.length) {
      break;
    }

    lastItem.attr('data-width', lastItemWidth);
    navWidth -= lastItem.outerWidth(true);
    lastItem.prependTo($moreMenuList);
    moreLinksCount++;
  }

  if (moreLinksCount === 0) {
    $moreMenu.addClass('hide');
  }

}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const windowwidth = $(window).width(),
      smallbreakpointwidth = 700;

    if( windowwidth > smallbreakpointwidth && typeof calcWidth === 'function' ) {
      calcWidth();
    }
  });
}
