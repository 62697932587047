import $ from 'jquery';

export default () => {

  FWW.cache = {};

  function getStories(query, callback) {
    $.ajax({
      type: "GET",
      url: "/wp/wp-admin/admin-ajax.php",
      data: query,
      dataType: 'json',
      success: callback
    });
  }

  function firstControlItem(browser: string) {
    const li = $('ul[data-browse="'+browser+'"] li')[0];
    if (li) {
      return $(li).find('a');
    }
    return false;
  }

  function controlItemData(controlItem) {
    var key: string, val:string;

    if (controlItem.data('topic')) {
      key = 'topic';
      val = controlItem.data('topic');
    }else if (controlItem.data('section')) {
      key = 'section';
      val = controlItem.data('section');
    }else if (controlItem.data('tag')) {
      key = 'tag';
      val = controlItem.data('tag');
    }else if (controlItem.data('type')) {
      key = 'type';
      val = controlItem.data('type');
    }

    return {'key':key, 'val':val};
  }

  function clickBrowseControl(clicked){

    const control = $('.content-browser__control');

    $('.content-browser__more').html('View More <span class="sr-only"> on '+ clicked.text()+'</span>');

    const activeTab = control.find("[aria-selected='true']");
    if (activeTab.length > 0) {
      activeTab.attr("aria-selected","false");
      const activeA =  activeTab.find('a');
      activeA.removeClass('active').attr("tabindex","-1").html(activeA.html().replace('<span class="sr-only"> (selected)</span>',''));;
    }

    clicked.addClass('active').attr("tabindex","0").html(clicked.text()+'<span class="sr-only"> (selected)</span>');;

    const tab = clicked.parent();
    tab.attr("aria-selected","true");
  }


  function renderContentBrowser(data, focusFirst: boolean) {
    let template = _.template($("#content-browser-template").html());
    $('.content-browser__results__pagination').remove();
    $('.content-browser__results__empty').remove();
    $('.content-browser__results').replaceWith(template(data));
    setTimeout(function () {
      $(window).trigger('content-browser-loaded');
      if (focusFirst) {
        $('.content-browser__results__block a').first().focus();
      }
    }, 1550);

  }

  function loadStories(clicked: object, action: String, section: String, moreRoot: String, focusFirst=true) {
    const results = $('.content-browser__results');
    const itemData = controlItemData(clicked);
    const more = $('.content-browser__more');

    results.addClass("loading");
    clickBrowseControl(clicked);

    if (moreRoot === 'key') {
      moreRoot = itemData.key;
    }
    more.attr('href', '/' + moreRoot + '/' + itemData.val);

    const cacheStr = section+itemData.key+itemData.val;
    if (FWW.cache[cacheStr]) {
      let res = FWW.cache[cacheStr];
      if (res.length === 9) {
        more.removeClass('hidden');
      } else {
        more.addClass('hidden');
      }
      renderContentBrowser({items: res, keyword: itemData.val.replaceAll('+','-')}, focusFirst);
      return;
    }

    let query = {
      'action': action,
      'section': section,
      'key': itemData.key,
      [itemData.key]: itemData.val.replace(/\+/g, ",")
    };

    /*
    let exclude = [];

    $('[data-postid]').each(function () {
      exclude.push($(this).data('postid'));
    });

    query['exclude'] = exclude;
    */

    getStories(query, function (res)  {
      FWW.cache[cacheStr] = res;
      if (res.length === 9) {
        more.removeClass('hidden');
      } else {
        more.addClass('hidden');
      }
      renderContentBrowser({items: res, keyword: itemData.val.replaceAll('+','-')}, focusFirst);
    });
  }

  function loadModalMenuStories() {
    let query = {
      'action': 'home_page_featured_stories',
    };

    let template = _.template($("#modal-stories-template").html());

    getStories(query, function (res) {
      if (!res) {
        return;
      }

      // Only need 3 stories
      res = res.slice(0, 3);
      $('.modal-menu__featured-stories').html(template({items: res})).addClass('loaded');
    });
  }

  $(function () {
    // Content Browser UI
    let  $tabFocus = 0;

    $('ul.content-browser__control__list').on("keydown", function (e) {
      const $tabs = $('ul.content-browser__control__list .content-browser__control__item a');
      // Move right
      if (e.keyCode === 39 || e.keyCode === 37) {
        $tabs[$tabFocus].setAttribute("tabindex", -1);
        if (e.keyCode === 39) {
          $tabFocus++;
          // If we're at the end, go to the start
          if ($tabFocus >= $tabs.length) {
            $tabFocus = 0;
          }
          // Move left
        } else if (e.keyCode === 37) {
          $tabFocus--;
          // If we're at the start, move to the end
          if ($tabFocus < 0) {
            $tabFocus = $tabs.length - 1;
          }
        }

        $tabs[$tabFocus].setAttribute("tabindex", 0);
        $tabs[$tabFocus].focus();
      }
    });

    // Load modal menu stories
    $('.siteheader__menu').one('mouseover', loadModalMenuStories);

    // Ajax for Tools & Materials browser
    $('ul[data-browse="tools-materials"] a').on('click', function (e) {
      loadStories($(this), 'get_articles', 'tools-materials', 'key');
      e.preventDefault();
    });

    // Ajax for video topic browser
    $('ul[data-browse="video"] a').on('click', function (e) {
      loadStories($(this), 'get_video_articles', 'video', 'videos');
      e.preventDefault();
    });

    // Ajax for Projects and Plan browser
    $('ul[data-browse="projects-plans"] a').on('click', function (e) {
      loadStories($(this), 'get_articles', 'projects-and-plans', 'projects-and-plans');
      e.preventDefault();
    });

    // Ajax for Video Workshop browser
    $('ul[data-browse="video-workshops"] a').on('click', function (e) {
      loadStories($(this), 'get_video_workshop', '', 'videoworkshops');
      e.preventDefault();
    });

    // Init load for Tools & Materials
    if ($('ul[data-browse="tools-materials"]').length) {
      let firstLiA = firstControlItem('tools-materials');
      loadStories(firstLiA, 'get_articles', 'tools-materials', 'key', false);
    }

    // Init load for Video
    if ($('ul[data-browse="video"]').length) {
      let firstLiA = firstControlItem('video');
      loadStories(firstLiA, 'get_video_articles', 'video', 'videos', false);
    }

    // Init load for Projects & Plans
    if ($('ul[data-browse="projects-plans"]').length) {
      let firstLiA = firstControlItem('projects-plans');
      loadStories(firstLiA, 'get_articles', 'projects-and-plans', 'projects-and-plans', false);
    }

    // Init load for Video Workshops
    if ($('ul[data-browse="video-workshops"]').length) {
      let firstLiA = firstControlItem('video-workshops');
      loadStories(firstLiA, 'get_video_workshop', '', 'videoworkshops', false)
    }
  });
}
